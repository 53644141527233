<template>
    <div class="number-range form-group" :class="{'open': open}">
        <div class="number-range-values" :class="{'placeholder': !values.length}" @click="open = !open">
            {{displayValue}}
        </div>
        <div class="number-range-inputs accordion" :class="{'open': open}">
            <div>
                <div class="input-group">
                    <input type="number" placeholder="From" v-model.number="localValues[0]" @change="update" :min="min" :max="max" />
                    <span class="separator">-</span>
                    <input type="number" placeholder="Through" v-model.number="localValues[1]" @change="update" :min="min" :max="max" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: [
        'min',
        'max',
        'name',
        'values',
        'placeholder'
    ],
    data: function(){
        return {
            open: false,
            localValues: [...this.values]
        }
    },
    computed: {
        displayValue: function(){
            if (this.values[0] === '' && this.values[1] === ''){
                return 'All';
            }
            if (this.values[0] === this.values[1]){
                return this.values[0]; 
            }
            if (this.values[0] === ''){
                return '<=' + this.values[1];
            } 
            if (this.values[1] === ''){
                return '>=' + this.values[0];
            }
            return this.values[0] + '-' + this.values[1];
        }
    },
    watch: {
        'localValues.0': function(newValue){
            //min changed and is higer than max
            if(+newValue > +this.localValues[1]){
                this.localValues[1] = this.localValues[0];
                return;
            }
        },
        'localValues.1': function(newValue){
            //max changed and is lower than min 
            if(+newValue < +this.localValues[0]){
                this.localValues[0] = this.localValues[1];
                return;
            }
        },
        localValues: {
            handler: function(){
                this.$emit("change", this.name, this.localValues);
            },
            deep: true
        }

    },
    created: function(){
        this.localValues = [
            this.values[0],
            this.values[1]
        ]
    }
}
</script>

<style lang="scss">
@import "../../assets/scss/variables.scss";

.number-range{
    position: relative;
    overflow: visible;
    height: 35px;


    .number-range-values{
        cursor: pointer;
        transition: $transition-standard;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        position: relative;

    }
    .number-range-inputs{
        position: relative;
        background: $white;
        z-index: 101;
        border-radius: $input-radius;

        > div {
            border: 1px solid nth($grays, 4);
            border-top-width: 0;
        }

        label{
            display: block;
            padding: $space-narrow $space-standard;
            margin: 0;
            white-space: nowrap;
            cursor: pointer;

            &:hover{
                background: nth($brands, 10);
            }

            &::before{
                @include checkbox;
            }

            &.selected{
                &::before{
                    @include checkbox-selected;
                }
            }

        }
    }

    &.open{
        .number-range-values{
            box-shadow: $shadow-standard;
            border-radius: 3px 3px 0 0;
            border-bottom-color: $white;
            background: $white;
        }

        .number-range-list{
            box-shadow: $shadow-standard;
            
        }
    }

}
</style>
