<template>
    <transition name="loading-cover">
        <div v-if="loading" class="loading-cover">
            <div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: ['loading']
}
</script>

<style lang="scss">
@import "../../assets/scss/variables.scss";
.loading-cover {

    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(nth($grays, 5), 0.2);
    z-index: 80;
    opacity: 1;
    transition: opacity 0.1s ease;

    >div {
        width: 100%;
        height: 50px;
        position: relative;
        display: flex;
        justify-content: center;
        top: -20%;

        $bump-series: 0.07s;
        $bump-delay: 0.3s;
        >div {
            height: 20px;
            width: 20px;
            border-radius: 10px;
            background: nth($greens, 4);
            transition: all 0.2s ease-out, margin-left 0.2s ease-in;
            position: relative;
            animation-duration: 2s;
            animation-name: jump;
            animation-iteration-count: infinite;
            animation-delay: $bump-series * 11 + $bump-delay;
            margin-left: 1px;

            &:nth-child(2){
                animation-delay: $bump-series * 10 + $bump-delay;
            }
            &:nth-child(3){
                animation-delay: $bump-series * 4 + $bump-delay;
                margin-lefT: 100px;
            }
            &:nth-child(4){
                animation-delay: $bump-series * 3 + $bump-delay;
            }
            &:nth-child(5){
                animation-delay: $bump-series * 0 + $bump-delay;
                margin-left: 40px;
            }


        }
    }

    &.loading-cover-enter-from {
        opacity: 0;

        >div {
            >div {
                transform: translateX(-50vw);
                &:nth-child(3), &:nth-child(5){
                    margin-left: 1px;
                }
            }
        }
    }
    &.loading-cover-leave-to {
        opacity: 0;
        transition: 0.1s ease 0.2s;

        > div {
            > div {
                transform: translateX(50vw);
                &:nth-child(3), &:nth-child(5){
                    margin-left: 1px;
                }
            }
        }
    }
}
@keyframes jump{
    from {
        top: 0px;
    }
    5% {
        top: -8px;
    }
    10% {
        top: 0px;
    }
    to {
        top: 0px;
    }
}
</style>