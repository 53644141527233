<template>
    <div class="multiselect" :class="{'open': open}" :name="name" v-if="modelValue !== undefined" @click.stop>
        <div class="multiselect-values" @click="open = !open">
            <transition-group name="cross-fade">
                <span v-for="value in modelValue" :key="value">{{options[value]}}<i @click.stop="select(value)" class="fa-regular fa-xmark"></i></span>
                <input 
                    key="input"
                    @keydown="keying" 
                    @click.stop="open = true"
                    @focus="open = true" 
                    type="text" 
                    v-model="searchTerm"
                    :class="{active: open || !modelValue.length}" :placeholder="open ? 'Filter options...' : placeholder" 
                />
            </transition-group>
            <i class="fas fa-chevron-down"></i>
        </div>
        <div class="multiselect-list accordion" :class="{'open': open}">
            <div>
                <transition-group name="autocordion">
                    <div class="autocordion" v-for="(label, value, i) in filteredOptions" :key="value">
                        <div>
                            <label 
                                @click.stop="select(value)" 
                                :class="{selection: i === selection, selected: modelValue.indexOf(value) !== -1}"
                                v-html="label"
                            >
                            </label>
                        </div>
                    </div>
                    <div key="a" class="autocordion" v-if="Object.keys(filteredOptions).length === 0">
                        <div>
                            <label>No matches</label>
                        </div>
                    </div>
                </transition-group>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, onBeforeUnmount, onMounted, ref, watch } from '@vue/runtime-core';

export default {
    props: [
        'options',
        'name',
        'modelValue',
        'placeholder'
    ],
    setup(props, {emit}){
        const open = ref(false); 
        const searchTerm = ref('');
        const close = () => {
            open.value = false;
        }
        const selection = ref(0);
        const filteredOptions = computed(() => {
            let options = Object.entries(props.options);
            let lower = searchTerm.value.toLowerCase();
            options = options.filter(x => x[1].toLowerCase().indexOf(lower) !== -1);
            return Object.fromEntries(options);
        });

        watch(open, (newValue) => {
            if (newValue === false){
                searchTerm.value = '';
            }
        });

        onMounted(() => {
            window.addEventListener("click", close);       
        });
        onBeforeUnmount(() => {
            window.removeEventListener("click", close);
        });

        const keying = function(event){
            if (event.keyCode === 38){
                //up
                selection.value = selection.value > 0 ? selection.value - 1: 0; 
                event.preventDefault();
            } else if (event.keyCode === 40){
                //down
                if (selection.value < Object.keys(filteredOptions.value).length - 1){
                    selection.value ++;
                }
                event.preventDefault();
            } else if (event.keyCode === 13){
                //enter, toggle
                select(Object.keys(filteredOptions.value)[selection.value]);
                event.preventDefault();
            } else if (event.keyCode === 27) {
                //escape, clear value, if clear then blur/close
                if (searchTerm.value){
                    selection.value = 0;
                    searchTerm.value = '';
                } else {
                    event.target.blur();
                    close();
                }
            } else if (event.keyCode === 8) {
                //backspace, if clear delete last selection
                if (!searchTerm.value && Object.keys(props.modelValue).length){
                    select(Object.values(props.modelValue).pop());
                    event.preventDefault();
                }
            } else if (event.keyCode === 9){
                //tabbing out, close
                event.target.blur();
                open.value = false;                
            }else {
                selection.value = 0;
            }
        }

        const select = function(value){
            const i = props.modelValue.indexOf(value);
            let newValue = props.modelValue.slice();
            if(i !== -1){
                newValue.splice(i, 1);
                emit("update:modelValue", newValue);
            } else {
                newValue.push(value);
                emit("update:modelValue", newValue);    
            }
        };

        return {open, searchTerm, selection, filteredOptions, keying, select}
    },
    emits: [
        'update:modelValue'
    ],
}
</script>

<style lang="scss">
@import "../../assets/scss/variables.scss";

.multiselect{
    position: relative;
    overflow: visible;
    padding-bottom: 0;
    margin-bottom: $space-standard;
    border-radius: $input-radius;

    input{
        border-radius: 0;
        border-width: 0;
        width: calc(100% - 8px);
        padding: 0;
        height: 0;

        &.active{
            height: 32px;
        }

        &:focus{
            outline: none;
            box-shadow: none;
        }
    }

    &:focus-within{
        box-shadow: 0 0 0 1px $green;
        
        .selection{
            box-shadow: 0 0 0 1px inset $green;
            z-index: 50;
        }
    }

    .multiselect-values{
        padding-right: 20px;
        cursor: pointer;
        transition: $transition-standard;
        position: relative;
        height: auto;
        min-height: 40px;

        > i{
            color: $font-color;
            position: absolute;
            width: 30px;
            right: 0;
            top: 0;
            text-align: center;
            line-height: 35px;
        }
        > label{
            padding: 0;
            margin: 0;
        }
        > span{
            background: nth($grays, 10);
            padding: $space-narrow;
            border: 1px solid nth($grays, 7);
            margin-right: $space-narrow;
            border-radius: $input-radius;
            display: inline-block;
            line-height: 18px;
            text-transform: capitalize;

            > i{
                padding: 0 $space-narrow; 

                &:hover{
                    color: $danger;
                }
            }

            +input{
                margin-top: 0;
            }
        }
    }
    .multiselect-list{
        position: absolute;
        top:100%;
        left: 0;
        right: 0;
        background: $white;
        z-index: 101;
        inline-size: max-content;
        min-width: 100%;
        list-style: none;
        padding-left: 0;

        > div {
            border: 1px solid nth($grays, 7);
            border-top-width: 0;
            border-radius: 0 0 $input-radius $input-radius;

        }

        label{
            display: block;
            padding: $space-narrow $space-standard $space-narrow $space-standard - 2px;
            border-left: 2px solid $white;
            border-right: 2px solid transparent;
            margin: 0;
            white-space: nowrap;
            cursor: pointer;
            text-transform: capitalize;


            &:hover {
                border-left-color: nth($brands, 10); 
                background: nth($brands, 10);
            }
            &.selected {
                border-left-color: $brand;
                background: nth($brands, 10);
            }

        }
    }

    &.open{
        .multiselect-values{
            box-shadow: $shadow-standard;
            border-radius: 3px 3px 0 0;
            border-bottom-color: $white;
            background: $white;
        }

        .multiselect-list{
            box-shadow: $shadow-standard;
            
        }
    }

}
</style>
