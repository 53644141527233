<template>
<div class="table-holder" ref="wrapper" @scroll="scroller">
    <div class="table-height" :style="{'height': `${rowHeight * totalRows}px`}">
        <table class="simple-table" :style="{transform : `translateY(${transform}px)` }" >
            <colgroup>
                <col v-if="openableSlot" style="{'width': 100 / totalLength + '%'}">
                <col span="1" v-for="(settings, column) in columns" :key="column" :style="{'width': (settings.width || 1) / totalLength * 100 + '%'}">
                <col v-if="controlsColumn" style="{'width': rowControlsWidth / totalLength * 100 + '%'}">
            </colgroup>
            <thead :class="{'filters-open': controlsOpen}">
                <tr v-if="!filtersOff" class="filters-row" :class="{open: controlsOpen}" >
                    <th v-if="openableSlot"></th>
                    <template v-for="(settings, column) in columns" :key="column">
                        <th>
                            <template v-if="column && settings.filterable !== false">
                                <div class="accordion" :class="{open: controlsOpen}">
                                    <div>
                                        <multiselect 
                                            v-if="settings.options"
                                            :placeholder="settings.title"
                                            :options="settings.options" 
                                            v-model="filters[column].value" 
                                            :name="column"
                                            ></multiselect>
                                        <div v-else-if="(filters[column].type === Boolean)" class="form-group">
                                            <label>
                                                <input type="checkbox" v-model="filters[column].value" /><span></span>                    
                                            </label>
                                        </div>
                                        <daterange 
                                            v-else-if="(filters[column].type === Date)"
                                            :values="filters[column].value"></daterange>
                                        <number-range 
                                            v-else-if="(filters[column].type === Number)"
                                            :values="filters[column].value"
                                            :name="column"
                                            :min="settings.min"
                                            :max="settings.max"
                                            ></number-range>
                                        <div v-else class="form-group">
                                            <input type="text" :placeholder="'Filter ' + settings.title" v-model="filters[column].value" />
                                            <a class="input-clear" @click="filters[column].value = ''"><i class="fas fa-times"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </th>
                    </template>
<!--                    <th>-->
<!--                        <div class="accordion" :class="{open: controlsOpen}">-->
<!--                            <div>-->
<!--                                <a class="input-clear clear-all-filters " @click="clearFilters"><i class="fas fa-backspace"></i></a>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </th>-->
                </tr>
                <tr>
                    <th v-if="openableSlot"></th>
                    <th v-for="(settings, column) in columns" :key="column" class="column-header" :style="{ 'top': `-${transform}px` }">
                        <span v-if="settings.sortable" @click="orderBy(column)" class="sortable">
                            {{settings.title}}
                            <i class="fa-light" 
                                :class="{
                                    'fa-sort-up' : order.prop === column && order.direction === 2,
                                    'fa-sort-down' : order.prop === column && order.direction === 1,
                                    'fa-sort hide': order.prop !== column || order.direction === 0
                                }">
                            </i>
                        </span>
                        <span v-else>{{settings.title}}</span>
                    </th>
                    <th v-if="controlsColumn" :style="{'top': `-${transform}px` }" class="column-header"><slot name="rowcontrolsHeader"></slot></th>
                </tr>
            </thead>
            <tbody>
                <template v-for="id in rows" :key="id">
                    <tr :class="{
                            'row-opener': openableSlot,
                            'open': openRow === id,
                            'selected': selectedRow === id,
                        }" 
                        @click="
                            openRow = (openRow === id ? null : id); 
                            $emit('click:row', id, $event);
                        ">
                        <td v-if="openableSlot"></td>
                        <template v-for="(settings, column) in columns" :key="column">
                            <td :data-title="column"><span v-html="output(collection[id], 0, settings, column)"></span></td>
                        </template>
                        <td v-if="controlsColumn" class="row-controls" @click.stop.prevent>
                            <slot name="rowcontrols" :record="collection[id] || {}"></slot>
                        </td>
                    </tr>
                    <tr v-if="openableSlot" class="row-openable">
                        <td :colspan="Object.keys(columns).length + (filtersOff ? 1 : 2)">
                            <transition name="autocordion">
                                <div class="autocordion" v-if="openRow !== null && openRow === id">
                                    <div>
                                        <slot name="openable" :record="collection[id]"></slot>
                                    </div>
                                </div>
                            </transition>
                        </td>
                    </tr>
                </template>
                <tr v-if="rows.length === 0">
                    <td :colspan="Object.keys(columns).length + (filtersOff ? 0 : 1)">
                        No data matches your query. <br /><a v-if="Object.values(filters).some(x => x.value.length)" @click="clearFilters">Clear all filters</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <loading-cover :loading="suspend"></loading-cover>
</div>
</template>

<script>
import Enum from '../../models/Enum.js';
import Email from '../../models/Email.js';

import Daterange from '../inputs/Daterange.vue';
import Multiselect from '../inputs/Multiselect.vue';
import Filepath from '../../models/Filepath.js';
import NumberRange from '../inputs/NumberRange.vue';
import Model from '../../models/Model.js';
import { computed, nextTick, onBeforeMount, onBeforeUnmount, onMounted, reactive, ref, watch } from '@vue/runtime-core';
import store from '../../store/store.js';
import FormatDataMixin from '../../mixins/FormatData.js'
import database from "@/store/database";
import LoadingCover from "@/components/utilities/LoadingCover";


    export default {
        props: [
            'model', 
            'records',
            'columns',
            'page',
            'perpage',
            'initialFilters',
            'filtersOff',
            'details',
            'rowControlsWidth',
            'multipage',
            'offset',
            'lazy',
            'selectedRow',
            'controlsOpen',
            'initialOrder',
        ],
        components: {
            LoadingCover,
            Multiselect,
            Daterange,
            NumberRange,
        },
        setup(props, {slots}){

            const {output} = FormatDataMixin();
            const suspend = ref(true);

            const collection = database[props.model.name];

            const cleanModel = new props.model();
            const filters = reactive({});
            
            const openableSlot = computed(() => {
                return !!(slots.openable && slots.openable({})[0].children.length);
            });
            const controlsColumn = computed(() => {
                return !!(slots.rowcontrols && slots.rowcontrols({})[0].children.length);
            });

            const order = reactive({prop: null, direction: 0});
            if (props.initialOrder){
                Object.assign(order, props.initialOrder);
            }
            const rowsSet = ref([]);
            const rows = ref([]);
            const rowHeight = 40;
            const wrapper = ref();
            const wrapperHeight = ref();
            const totalRows = ref(0);
            const scrollTop = ref(0);
            const startRow = computed(() => Math.floor(scrollTop.value / rowHeight));
            const maxRows = computed(() => Math.ceil(wrapperHeight.value / rowHeight) + 1);
            const openRow = ref();
            const translate = computed(() => scrollTop.value % rowHeight);
            const transform = computed(() => scrollTop.value - translate.value);
            const totalLength = computed(() => Object.values(props.columns).reduce((x, y) => x + (y.width || 1), (props.rowControlsWidth || 0) + (openableSlot ? 1 : 0)));


            const orderBy = (column) => {
                order.direction = order.prop !== column ? 1 : order.direction === 2 ? 0 : order.direction + 1; 
                order.prop = column;
            }
            
            const resizer = new ResizeObserver( () => {
                wrapperHeight.value = wrapper.value.offsetHeight;
            });
            const scroller = ($event) => {
                scrollTop.value = $event.target.scrollTop;
            };



            /** START BEFORE MOUNT /**/
            onBeforeMount(() => {

                //set up the filters object if we have filters
                if (props.filtersOff !== true) {
                    //setup our filters object
                    for (let i in props.columns) {
                        if (props.columns[i].filterable !== false) {
                            let type;
                            if (props.columns[i].type) {
                                type = props.columns[i].type;
                            } else {
                                let prop = i.split(".");
                                let model = cleanModel;
                                let getDeep;
                                let level = 0;

                                while (level < prop.length) {
                                    if (Array.isArray(model.typeof[prop[level]]) && model.typeof[prop[level]][0].prototype instanceof Model) {
                                        model = new model.typeof[prop[level]][0]();

                                    } else if (model.typeof[prop[level]].prototype instanceof Model) {
                                        model = new model.typeof[prop[level]]();
                                    }
                                    getDeep = prop[level];
                                    level++;
                                }
                                type = model.typeof[getDeep];
                            }

                            filters[i] = {type};
                            switch (true) {
                                case type === Number:
                                case type instanceof Enum:
                                    filters[i].value = [];
                                    break;
                                default:
                                    filters[i].value = '';
                            }
                        }
                    }
                }
            });
                
            onMounted(() => {
                
                //start watching for changes that would cause a re-evaluation of the table
                watch([filters, order, props.records, suspend], () => {
                    console.log(`${props.model.name} - Table watch saw ${props.records.length}`);
                    //only recalculate when not suspended
                    if (suspend.value){
                        return;
                    }

                    //track if we're waiting on something
                    let filterPromises = [];

                    //start with all the potential ids we were given 
                    let final = [...props.records];
                    
                    //filter every prop
                    for (let filterProp in filters){
                        if (
                            (
                                filters[filterProp].type === Number 
                                || filters[filterProp].type instanceof Enum
                            )
                            && filters[filterProp].value.length
                        ){
                            //!!need to handle special cases
                            console.log("ARRAY");

                        } else if (filters[filterProp].value.length > 3){

                            //filter a specific value
                            let actualPath = props.columns[filterProp].filter || filterProp;

                            //ask for the filter set
                            if(!store.getters.hasInformation({model: props.model, type: 'contains', prop: actualPath, spec: filters[filterProp].value})){
                                suspend.value = true;
                                filterPromises.push(store.dispatch('getInformation', {model: props.model, type: 'contains', prop: actualPath, spec: filters[filterProp].value}));
                            }

                            let thisFilter = store.state.AppDataInformation[props.model.name]['contains'][actualPath][filters[filterProp].value];
                            console.log(thisFilter);
                            final = final.filter(id => {
                                return thisFilter.indexOf(parseInt(id)) !== -1;
                            });
                        }
                        
                    }

                    if (order.prop && order.direction){
                        
                        let actualProp = typeof props.columns[order.prop].sortable === 'string' ? props.columns[order.prop].sortable : order.prop; 
                        //ask for the order set
                        let ordered = database[props.model.name].orderBy(actualProp, order.direction, final);
                        if(!ordered.loaded){
                            suspend.value = true;
                            filterPromises.push(ordered.loader);
                        }
                        
                        final = ordered.toArray();
                    }

                    Promise.all(filterPromises).then((result) => {
                        console.log("promises done");
                        suspend.value = false;
                        return result;
                    });

                    //not redrawing table until we're ready to
                    if (suspend.value){
                        return;
                    }
                    
                    totalRows.value = final.length;
                    
                    console.log("filtered");
                    rowsSet.value.length = 0;
                    rowsSet.value.push(...final);

                }, {deep: true});

                //recuts data based on scroll and height
                watch ([startRow, wrapperHeight, rowsSet], () => {
                    console.log("start cutting");
                    let final = [...rowsSet.value];
                    if (props.lazy && final.length){
                        final = final.slice(startRow.value, startRow.value + maxRows.value);
                    }
                    rows.value.length = 0;
                    rows.value.push(...final);
                }, {deep: true});

                suspend.value = false;
            });
            /** END BEFORE MOUNT /**/

             



            onMounted(() => {
                nextTick(() => {
                    wrapperHeight.value = wrapper.value.offsetHeight;
                    resizer.observe(document.body);
                });
            });

            onBeforeUnmount(() => {
                resizer.disconnect();
            });








            return {
                filters,
                openableSlot,
                controlsColumn,

                collection, 
                rows, 
                order, 
                wrapper,
                totalRows,
                openRow,
                rowHeight,
                translate, 
                transform,
                totalLength,
                cleanModel,
                suspend,

                output,
                scroller,
                orderBy,

                Enum,
                Email,
                Filepath,
                Model,
            };
        },
        emits: ['change-page', 'change-page-count', 'click:row', 'filtered', 'ordered'],
        
        watch: {
            
            // '$route.query': function(newQuery){
            //     this.queryChange(newQuery);
            // },
            // 'collection._loaded': function(loaded){
            //     if (loaded){
            //         this.findLongestValues();
            //     }            
            // }
        },
    }
</script>

<style lang="scss">

@import "../../assets/scss/variables.scss";

.table-holder{
    position: relative;
}

.sortable{
    cursor: pointer;
    @include noselect;

    .hide{
        color: rgba(255,255,255,0);
        transition: $transition-standard;
    }

    &:hover{
        .hide{
            color: nth($grays, 6);
        }
    }
}
.simple-table{
    table-layout: fixed;
    width: 100%;
    
    > thead {

        .column-header{
            position: sticky;
            top: 0;
            z-index: 75;
        }

        > .filters-row{
            text-align: center;

            th{
                padding: 0 $space-narrow;
                max-width: 50px;
                
                .form-group{
                    padding-top: $space-wide;
                    padding-bottom: 15px;
                    padding: 0;
                    margin: $space-wide 0;

                    &.multiselect, 
                    &.number-range{
                        height: $space-wide + 15px + 35px;
                        height: 40px;
                    }
                }
            }
            .clear-all-filters{
                display: block;
                width: 45px;
                line-height: 30px;
                font-size: 30px;
                text-align: center;
                margin: 0 auto;
                color: $green;
                transition: $transition-standard;
                
                &:hover{
                    color: nth($greens, 6);
                }
            }
            
            .number-range{
                .number-range-inputs{
                    width: 150px;
                    left: 50%;
                    margin-left: -75px;

                }
            }
        }
    }
    > tbody {
        > tr {
            /*gray background for hovered rows*/
            &:hover {
                > td{
                    background: nth($grays, 10);
                }
            }

            &.row-opener {
                > td {                
                    cursor: pointer;
                }
                &:hover {
                    > td, td:first-child {
                        background: nth($brands, 10);
                    }
                }

                > td:first-child{
                    position: relative;
                    background: $white;

                    &::before{
                        content: "";
                        position: absolute;
                        display: block;
                        background: $font-color;
                        height: 12px;
                        width: 12px;
                        left: 50%;
                        margin-left: -6px;
                        margin-top: -10px;
                        top: 50%;
                        transform: rotate(45deg);
                        transition: margin-top 0.15s ease-in; 
                    }
                    &::after{
                        content:"";
                        position: absolute;
                        display: block;
                        height: 14px;
                        width: 14px;
                        background: inherit;
                        top: 50%;
                        margin-top: -14px;
                        left: 50%;
                        margin-left: -7px;
                        transform: rotate(45deg);
                        transition: margin-top 0.15s ease-in, transform 0.15s ease-in; 
                    }
                }
                &.open{
                    > td {
                        background: nth($brands, 10);

                        &:first-child {
                            &::before{
                                transform: rotate(-135deg);
                                margin-top: -4px;
                            }
                            &::after{
                                transform: rotate(225deg);
                                margin-top: -2px;
                            }
                        }
                    }
                }
            }
            &.row-openable{
                > td{
                    padding: 0;
                    background: nth($grays, 10);
                    box-shadow: 0px 1px 5px inset rgba(0,0,0,0.7);
                    border-bottom-width: 0;
                }
            }
            &:not(.row-openable){
                td {
                    > span{
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: block;
                    }
                    height: 44px;
                }
            }
            > td{
                &:first-child{
                    padding-left: $space-wide;
                }
            }
            &.selected{
                td {
                    background: nth($brands, 10);
                }
            }
            a:not(.button){
                line-height: 35px;
                display: inline-block;
            }
        }
    }

    .row-controls {
        button, .button {
            min-width: 40px - $space-narrow * 2;
            text-align: center;
            padding: 0 $space-narrow;
            line-height: 40px - $space-narrow * 2;

            > i{
                margin-right: 0;
            }
        }
    }


    .components{
        .component{
            flex: 1 1 auto;
        }
    }
}
.component{
    .simple-table{
        width: auto;
        margin: 0 0 - $space-wide;

        &:last-child{
            margin-bottom: 0 - $space-wide;
            border-radius: 0 0 5px 5px;
            overflow: hidden;
        }
    }
    header + .simple-table, 
    .simple-table:first-child{
        margin-top: 0 - $space-wide;
    }

}

@mixin narrow-simple-table{
    display: block;
    
    .magic-alignment{
        display: none;
    }

    > thead{
        display: block;

        tr{
            &:first-child{
                display: none;
            }
            &.filters-row {
                display: block;
                width: 100%;

                th{
                    display: block;
                    width: 100%;
                    max-width: none;
                    border-bottom-width: 0;

                    .form-group{
                        margin-bottom: 0;
                    }
                }
            }
            &:not(.filters-row){
                display: none;
            }
        }

    }

    > tbody {
        display: block;

        > tr {
            display: table;
            width: 100%;
            border-bottom: $space-standard solid nth($grays, 9);
            
            > td {
                display: table-row;

                > span, > a {
                    display: table-cell;
                    padding: $space-narrow $space-standard;
                }

                &::before {
                    content: attr(data-title);
                    display: table-cell;
                    font-weight: $font-bold;
                    width: 25%;
                    padding: $space-narrow $space-standard;
                }
            }
        }
    }           
}


@media (min-width: $break-wide + 1){
    .fleet-dashboard .simple-table{
        @include narrow-simple-table;
    }
}
@media (max-width: $break-mobile){
    .simple-table{
        @include narrow-simple-table;
    }
}

</style>