<template>
    <div class="daterange form-group" :class="{'open': open}">
        <div class="daterange-values" @click="open = !open">
            <span>{{formattedValue}}</span>
        </div>
    </div>
</template>

<script>

export default {
    props: [
        'name',
        'values'
    ],
    data: function(){
        return {
            open: false
        }
    },
    methods: {
        select: function(value){
            console.log(value);
            const i = this.values.indexOf(value);
            let newValue = this.values.slice();
            if(i !== -1){
                newValue.splice(i, 1);
                this.$emit("change", this.name, newValue);
            } else {
                newValue.push(value);
                this.$emit("change", this.name, newValue);    
            }
        }
    },
    computed: {
        formattedValue: function(){
            let formattedValueBuilder = "";

            if (!this.values.start && !this.values.end){
                return "From - To";
            }  

            if (!this.values.end && this.values.start){
                formattedValueBuilder += "After ";
            }
            if (this.values.start) {
                formattedValueBuilder += this.values.start;
            } else if (this.values.end) {
                formattedValueBuilder += "Before ";
            }
            if (this.values.end){
                if (this.values.start){
                    formattedValueBuilder += " - ";
                }
                formattedValueBuilder += this.values.end;
            }
            return formattedValueBuilder;
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/scss/variables.scss";

</style>
