import Model from '../models/Model.js'
import Filepath from '../models/Filepath.js';
import Collection from '../models/Collection.js';

export default function () {
    const formattedValue = function(record, level, settings, path){

        var newSettings = Object.assign({}, settings);
        delete newSettings.format;

        if (settings.format === "money"){
            //get the value without it knowing it needs formatted
            var dollars = output(record, level, newSettings, path);
            if (isNaN(dollars)){
                return settings && Object.hasOwnProperty.call(settings, 'NA') ? settings.NA : '--';
            }
            return "$" + new Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(dollars);
        }

        let formattedValueBuilder = settings.format;
        try {
            settings.format
                .match(/[^{}]+(?=})/g)
                .map(match => {

                    var innerPath = match;
                    if (innerPath.indexOf("&&") !== -1){
                        var conditionalSplit = innerPath.split("&&");
                        innerPath = conditionalSplit[0]

                        let tests = conditionalSplit[1].split("==");
                        let seeker = tests[0].split(".");
                        let curRecord = record;
                        var i = 0;
                        while(i < seeker.length){
                            curRecord = curRecord[seeker[i]]
                            i++;
                        }
                        if(curRecord + "" !== tests[1]){
                            formattedValueBuilder = null;
                            return;
                        }
                    }

                    let out = output(record, level || 0, newSettings, innerPath);
                    if (out === (settings && Object.hasOwnProperty.call(settings, 'loading') ? settings.loading : 'Loading...')){
                        formattedValueBuilder = out;
                        return; 
                    }

                    formattedValueBuilder = formattedValueBuilder.replaceAll('{' + match + '}', out);
                    return;
                });

        } catch(e){
            console.log(e);
            return formattedValueBuilder;
        }
        return formattedValueBuilder;
    };
    const listValue = function(list, settings){
        list = list.filter(x => x !== null);
        var duplicates = false;
        var build = '';

        //dupe check
        if(new Set(list).size !== list.length){
            duplicates = {};
            list.forEach(x => duplicates[x] && duplicates[x]++ || (duplicates[x] = 1));
            //deduped list
            list = [...new Set(list)];
            //object of every value with count > 1
            duplicates = Object.fromEntries(Object.entries(duplicates).filter(x => x[1] > 1));
        }

        if (list.length === 0){
            return settings && Object.hasOwnProperty.call(settings, 'NA') ? settings.NA : '--';
        }

        if (settings && settings.multiple === 'count'){
            build = (list.length);
        } else if (!settings || list.length === 1){
            build = list.join("");
        } else if (settings.multiple === 'more' && list.length > 2){
            build = `${list[0]}, +${list.length - 1} more`;
        } else if (settings.multiple == 'list' || settings.multiple == 'more'){
            if (list.length > 2){
                build = list.slice(0, list.length - 2).join(", ") + ", " + list.slice(list.length - 2).join(" and ");
            } else if (list.length === 2){
                build = list.join(" and ");
            } else {
                build = list[0];
            }
        } else if (settings.multiple === 'ifcount' && list.length > 1) {
            build = `Multiple (${list.length})`;
        } else if (settings.multiple && settings.multiple.indexOf('{count}') !== -1){
            build = settings.multiple.replace("{count}", list.length);
        } else {
            build = list.join("");
        }

        for (var dupe in duplicates){
            build = build.replace(dupe, `${dupe} (x${duplicates[dupe]})`);
        }
        return build;
    };
    const getProp = function(path, level){
        return path.split(".")[level] || null;
    };
    const output = function(record, level, settings, path = ''){

        //empty/error/not loaded yet
        if (record === null || record === undefined){
            return settings && Object.hasOwnProperty.call(settings, 'NA') ? settings.NA : '--';
        }
        if (record instanceof Model && record.__error){
            return `[Error('${record.__error.status}')]`;
        }
        if (record instanceof Model && record._loaded === false
            || record[prop] instanceof Model && record[prop]._loaded === false){
            return settings && Object.hasOwnProperty.call(settings, 'loading') ? settings.loading : 'Loading...';
        }


        let prop = getProp(path, level);

        //we're looking deeper
        if (getProp(path, level + 1)){
            
            if (record[prop] instanceof Collection){
                if (getProp(path, level + 1) === 'first'){
                    return output(record[prop].first(), level + 2, settings, path);
                }
                if (getProp(path, level + 1) === 'last'){
                    return output(record[prop].last(), level + 2, settings, path);
                }

                let build = [];
                for (let subRecord in record[prop]){
                    build.push(output(record[prop][subRecord], level + 1, settings, path));
                }
                return listValue(build, settings);
            }

            //this prop is an array, get many of this level 
            if (Array.isArray(record.typeof[prop])){
                if (getProp(path, level + 1) === 'first'){
                    return output(record[prop][0], level + 2, settings, path);
                }
                if (getProp(path, level + 1) === 'last'){
                    return output(record[prop].pop(), level + 2, settings, path);
                }

                let build = [];
                for (let subRecord in record[prop]){
                    build.push(output(record[prop][subRecord], level + 1, settings, path));
                }
                return listValue(build, settings);
            }

            //not an array, just get the next level
            return output(record[prop], level + 1, settings, path);

        }

        //formatting will call output again with each property as needed
        if (settings && Object.hasOwnProperty.call(settings, 'format')) {

            if (record instanceof Collection){

                let build = [];
                for (let subRecord in record){
                    build.push(formattedValue(record[subRecord], 0, settings, path));
                }
                return listValue(build, settings);
            }
            if (Array.isArray(record)){
                let build = [];
                for (let subRecord in record){
                    build.push(formattedValue(record[subRecord], 0, settings, path));
                }
                return listValue(build, settings);
            }

            return formattedValue(record, level, settings, path);
        }

        //we're looking at a collection and no formatting or going deeper
        if (record instanceof Collection || Array.isArray(record)){
            let build = [];
            for (let subRecord in record){
                build.push(output(record[subRecord], level, settings, path));
            }
            return listValue(build, settings);        
        }

        //standard values
        if (Array.isArray(record[prop])){
            return listValue(record[prop], settings);
        }
        if (record.typeof){
            if (record.typeof[prop] === Filepath){
                return "A LINK";
            }
            if (record.typeof[prop] === Boolean){
                return record[prop];
            }
            if (record.typeof[prop] === Date){
                return record[prop].toLocaleDateString('en-US');
            }
        }
        
        //never print a model
        if (record[prop] instanceof Model || record[prop] instanceof Collection){
            return 'NOPE';
        }
        return record[prop];
    };

    return {formattedValue, listValue, getProp, output}
}
